import { observer } from 'mobx-react';
import React, { FC, useState } from 'react';
import { LightButton } from '../buttons/light-button/LightButton';
import styles from './DropdownMultiselect.module.scss';
import { Checkbox } from '../checkbox/Checkbox';
import filterIcon from './../../../assets/icons/filter_icon.png';
import {
  CheckboxGroupModelType,
  DropdownMultiselectModelType,
} from '../../../internal';
import { useDetectClickOutside } from 'react-detect-click-outside';

export interface IDropdownMultiselectProps {
  model: DropdownMultiselectModelType;
}

export const DropdownMultiselect: FC<IDropdownMultiselectProps> = observer(
  ({ model }) => {
    const ref = useDetectClickOutside({
      onTriggered: () => {
        model.isOpen && model.toggleIsOpen();
      },
    });

    if (!model.onlyDropdown) {
      return (
        <div
          ref={ref}
          className={styles.dropdownMultiselectWrapper}
          id="dropdownMultiselect"
          data-id={model.id}
        >
          <div className={styles.dropdownMultiselect}>
            <p
              style={{ textAlign: 'left' }}
              onClick={(e: any) => {
                e.stopPropagation();
                model.toggleIsOpen();
              }}
            >
              {model.selectedOptionsArray.length === model.optionsArray.length
                ? 'Sve kolone'
                : 'Odabrano: ' + model.selectedOptionsArray.length + ' kolona'}
            </p>
            {model.isOpen && (
              <div
                style={{ top: '110%' }}
                className={styles.dropdownMultiselectOptionsWrapper}
              >
                <div className={styles.dropdownMultiselectOptions}>
                  {model.optionsArray.map((option: any, i: number) => {
                    return (
                      <label
                        htmlFor={option.id}
                        className={styles.dropdownMultiselectOption}
                        key={option.id}
                        onClick={(e: any) => {
                          e.stopPropagation();
                        }}
                      >
                        <Checkbox model={model.optionsArray[i]} />
                      </label>
                    );
                  })}
                </div>
                <div className={styles.resetButton}>
                  <LightButton
                    onClick={() => model.checkAll()}
                    model={model.resetButton}
                  >
                    Resetuj
                  </LightButton>
                </div>
              </div>
            )}
          </div>
        </div>
      );
    }

    if (model.onlyDropdown && model.type === 'single') {
      return (
        <div className={styles.dropdownMultiselectOptionsWrapper}>
          <div className={styles.filterHeader}>
            <img className={styles.filterIcon} src={filterIcon} />
            <span className={styles.filterHeaderTitle}> Filteri </span>
          </div>
          <div className={styles.dropdownMultiselectOptions}>
            {model.options.checkboxesArray.map((option: any, i: number) => {
              return (
                <label
                  htmlFor={option.id}
                  className={styles.dropdownMultiselectOption}
                  key={option.id}
                  onClick={(e: any) => {
                    e.stopPropagation();
                  }}
                >
                  <Checkbox model={model.optionsArray[i]} />
                </label>
              );
            })}
          </div>
          <div
            style={{
              background: model.id === 'category_name' ? '#DCF1F9' : '',
            }}
          >
            <div className={styles.resetButton}>
              <LightButton
                onClick={() => model.checkAll()}
                model={model.resetButton}
              >
                Resetuj
              </LightButton>
            </div>
          </div>
        </div>
      );
    }

    if (model.onlyDropdown && model.type === 'nested') {
      return (
        <div className={styles.dropdownMultiselectOptionsWrapper}>
          <div className={styles.filterHeader}>
            <img className={styles.filterIcon} src={filterIcon} />
            <span className={styles.filterHeaderTitle}> Filteri </span>
          </div>
          <div className={styles.dropdownMultiselectOptions}>
            {model.visibleNestedOptionsArray?.map(
              (nestedOptionsData: CheckboxGroupModelType) => {
                return (
                  <React.Fragment key={nestedOptionsData.label}>
                    <p
                      style={{
                        textAlign: 'left',
                        fontFamily: 'Yettel-Bold',
                        padding: '8px',
                      }}
                    >
                      {nestedOptionsData.label}
                    </p>
                    {nestedOptionsData.checkboxesArray.map((checkbox: any) => (
                      <React.Fragment key={checkbox.id}>
                        {checkbox.id !== 'allOptions' && (
                          <label
                            htmlFor={checkbox.id}
                            className={styles.dropdownMultiselectOption}
                            onClick={(e: any) => {
                              e.stopPropagation();
                            }}
                          >
                            <Checkbox model={checkbox} />
                          </label>
                        )}
                      </React.Fragment>
                    ))}
                  </React.Fragment>
                );
              }
            )}
          </div>
          <div
            style={{
              background: model.id === 'subcategory_name' ? '#DCF1F9' : '',
            }}
          >
            <div className={styles.resetButton}>
              <LightButton
                onClick={() => model.checkAll()}
                model={model.resetButton}
              >
                Resetuj
              </LightButton>
            </div>
          </div>
        </div>
      );
    }

    return <></>;
  }
);
