import { ApiListResponse, ApiObjectResponse, RootType } from '../../internal';
import { getRoot, types } from 'mobx-state-tree';
import { HttpVerbs } from '../../constants/verbs';
import { BANNER_PAGE_SIZE } from '../../constants/banners';

export interface BannersApiType {
  id?: string;
  isActive?: boolean;
  desktopImage: string | null;
  mobileImage: string | null;
  author?: string;
  startDate?: string;
  endDate?: string;
  url?: string;
  position?: number;
  name?: string;
}

export const BannersApi = types
  .model('BannersApi', {})
  .views((self) => {
    return {
      get root(): RootType {
        return getRoot(self);
      },
    };
  })
  .actions((self) => {
    return {
      async create(data: BannersApiType) {
        try {
          return await self.root.axios.requestData({
            type: HttpVerbs.POST,
            path: '/banners',
            data,
          });
        } catch (err) {
          self.root.showFailure('e400');
          throw err;
        }
      },
      async update(bannerId: string, data: BannersApiType) {
        try {
          return await self.root.axios.requestData({
            type: HttpVerbs.PATCH,
            path: `/banners/${bannerId}`,
            data,
          });
        } catch (err) {
          self.root.showFailure('e400');
          throw err;
        }
      },
      async toggleActive(bannerId: string, isActive: boolean) {
        try {
          return await self.root.axios.requestData({
            type: HttpVerbs.PATCH,
            path: `/banners/${bannerId}`,
            data: { isActive: Number(isActive) },
          });
        } catch (err) {
          self.root.showFailure('e400');
          throw err;
        }
      },
      async get(bannerId: string): Promise<BannersApiType | undefined | null> {
        const response: ApiObjectResponse<BannersApiType> =
          await self.root.axios.requestData({
            type: HttpVerbs.GET,
            path: `/banners/${bannerId}`,
          });
        return response?.data;
      },
      async getList(page: number): Promise<ApiListResponse<BannersApiType>> {
        return await self.root.axios.requestData({
          type: HttpVerbs.GET,
          path: `/banners?page=${page}&limit=${BANNER_PAGE_SIZE}`,
        });
      },
      async delete(bannerId: string) {
        try {
          return await self.root.axios.requestData({
            type: HttpVerbs.DELETE,
            path: `/banners/${bannerId}`,
          });
        } catch (err) {
          self.root.showFailure('e400');
          throw err;
        }
      },
      async uploadImage(file: File, fileKey: string) {
        try {
          return await self.root.axios.uploadFile({
            path: '/banners/upload',
            file,
            fileKey,
          });
        } catch (err) {
          self.root.showFailure('e400');
          throw err;
        }
      },
      async changeOrder(bannerId: string, bannerType: string, change: number) {
        try {
          return await self.root.axios.requestData({
            type: HttpVerbs.PATCH,
            path: `/banners/order/${bannerId}`,
            data: { bannerType, change },
          });
        } catch (err) {
          self.root.showFailure('e400');
          throw err;
        }
      },
    };
  });
