import { Instance, types } from 'mobx-state-tree';

export const BannersListElementModel = types
  .model({
    id: types.number,
    isActive: types.number,
    showEveryTime: types.number,
    desktopImage: types.maybeNull(types.string),
    mobileImage: types.maybeNull(types.string),
    author: types.string,
    startDate: types.string,
    endDate: types.string,
    url: types.string,
    order: types.number,
    name: types.string,
    htmlContent: types.maybeNull(types.string),
    bannerType: types.string,
  })
  .views((_) => {
    return {};
  })
  .actions((_) => {
    return {};
  })
  .named('BannersListModel');

export type BannersListElementModelType = Instance<
  typeof BannersListElementModel
>;
