import { useTranslation } from 'react-i18next';
import {
  AdListElemModelType,
  ColumnModelType,
  PageRoutes,
  useMst,
} from '../../../internal';
import { copyToClipboard } from '../../../utils/methods';
import styles from './AdTableRow.module.scss';
import linkIcon from '../../../assets/icons/link_icon.png';
import { DownloadCSVTable } from './ad-details/download-csv-table/DownloadCSVTable';
import * as React from 'react';

export const Content = (props: {
  rowModel: any;
  column: ColumnModelType;
}): any => {
  const { rowModel, column } = props;
  const {
    router: { navigate },
    currentPage,
  } = useMst();
  const { t } = useTranslation();

  const handleSpenderType = (spenderType: string) => {
    let spenderString = '';
    if (!spenderType) {
      return '';
    }
    const spenderArray = spenderType.split(',');
    spenderArray.forEach((spenderType: any) => {
      if (spenderType === 'ARPU_RANK1') {
        spenderString += t('user_segments:mobile_network_big_spenders') + ',';
      } else if (spenderType === 'ARPU_RANK2') {
        spenderString +=
          t('user_segments:mobile_network_medium_spenders') + ',';
      } else {
        spenderString += t('user_segments:mobile_network_small_spenders');
      }
    });
    return spenderString;
  };

  const handleAdCodeType = (adCodeType: string) => {
    if (!adCodeType.includes(',')) {
      return t(`ad_settings:${adCodeType}`);
    }

    const adCodeTypeSplitArray = adCodeType.split(',');
    const adCodeTypeSplitArrayTranslation = adCodeTypeSplitArray.map(
      (adCodeType) => t(`ad_settings:${adCodeType}`)
    );
    const finalAdCodeTypeString = adCodeTypeSplitArrayTranslation.join(', ');
    return finalAdCodeTypeString;
  };

  if (
    !rowModel[column.id] &&
    column.id !== 'ad_description' &&
    column.id !== 'ad_gender' &&
    column.id !== 'ad_territory_type' &&
    column.id !== 'ad_common_code'
  ) {
    return <div>-</div>;
  }

  switch (column.id) {
    case 'ad_status':
      return (
        <div className={styles.TableDataStatus}>
          <div className={styles['Status-' + rowModel.ad_status]}></div>
          {t(`ad_status:${rowModel.ad_status}`)}
        </div>
      );
    case 'status':
      return (
        <div className={styles.TableDataStatus}>
          <div className={styles['Status-' + rowModel.status]}></div>
          {t(`ad_status:${rowModel.status}`)}
        </div>
      );
    case 'price_basic_per_day':
      return <>{rowModel.price_basic_per_day} RSD</>;
    case 'price_targeted_per_day':
      return <>{rowModel.price_targeted_per_day} RSD</>;
    case 'price_top_per_day':
      return <>{rowModel.price_top_per_day} RSD</>;
    case 'subtotal':
      return <>{rowModel.subtotal} RSD</>;
    case 'subtotal_per_day':
      return <>{rowModel.subtotal_per_day} RSD</>;
    case 'start':
      return (
        <>
          {new Date(rowModel.start).toLocaleDateString('de-DE', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          })}
        </>
      );
    case 'end':
      return (
        <>
          {new Date(rowModel.end).toLocaleDateString('de-DE', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          })}
        </>
      );

    case 'ad_date_code_start':
      return (
        <>
          {new Date(rowModel.ad_date_code_start).toLocaleDateString('de-DE', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          })}
        </>
      );

    case 'ad_date_code_end':
      return (
        <>
          {new Date(rowModel.ad_date_code_end).toLocaleDateString('de-DE', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          })}
        </>
      );
    case 'ad_date_start':
      return (
        <>
          {new Date(rowModel.ad_date_start).toLocaleDateString('de-DE', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          })}
        </>
      );
    case 'ad_date_end':
      return (
        <>
          {new Date(rowModel.ad_date_end).toLocaleDateString('de-DE', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          })}
        </>
      );
    case 'ad_url':
      return (
        <div className={styles.Link}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={rowModel.ad_url}
            className={styles.DetailsLink}
          >
            Link
          </a>
          <img
            src={linkIcon}
            alt="link"
            className={styles.CopyIcon}
            onClick={() => {
              copyToClipboard(rowModel[column.id]);
              currentPage.root.showSuccess(t('offer:link_copy_success'));
            }}
          />
        </div>
      );
    case 'ad_store_location_type':
      return <>{t(`ad_settings:${rowModel.ad_store_location_type}`)}</>;
    case 'ad_code_type':
      return <>{handleAdCodeType(rowModel.ad_code_type)}</>;
    case 'ad_with_code':
      return <>{t(`ad_settings:${rowModel.ad_with_code}`)}</>;
    case 'ad_territory_type':
      return (
        <>
          {rowModel.ad_territory_type === null
            ? '-'
            : rowModel.ad_territory_type === ''
            ? 'Oba'
            : t(`ad_settings:${rowModel.ad_territory_type}`)}
        </>
      );
    case 'ad_gender':
      if (rowModel.ad_gender === '') {
        return 'Oba';
      }
      if (rowModel.ad_gender === null) {
        return '-';
      }
      if (rowModel.ad_gender === 'Ž') {
        return 'Ženski';
      } else {
        return 'Muški';
      }
    case 'ad_visual_type':
      return <>{rowModel.ad_visual_type === 'top' ? 'Top' : 'Besplatan'}</>;
    case 'ad_type':
      return <>{t(`ad_settings:adType_${rowModel.ad_type}`)}</>;
    case 'ad_recommended':
      return <>{rowModel.ad_recommended === '0' ? 'Ne' : 'Da'}</>;
    case 'ad_common_code':
      if (rowModel.ad_common_code) {
        return <>{rowModel.ad_common_code}</>;
      } else {
        const doubleLinkStatuses = ['expired', 'code_spent', 'stopped'];
        if (doubleLinkStatuses.includes(rowModel.ad_status)) {
          return (
            <div style={{ display: 'inline-flex', gap: 5 }}>
              <DownloadCSVTable
                adId={rowModel.ad_id}
                partnerId={rowModel.partner_id}
                reference={rowModel.ref}
                unUsed={true}
                label="Dodati,"
              />
              <DownloadCSVTable
                adId={rowModel.ad_id}
                partnerId={rowModel.partner_id}
                reference={rowModel.ref}
                label="Preuzeti"
              />
            </div>
          );
        } else {
          return (
            <DownloadCSVTable
              adId={rowModel.ad_id}
              partnerId={rowModel.partner_id}
              reference={rowModel.ref}
              unUsed={true}
              label="Dodati"
            />
          );
        }
      }
    case 'ad_discount_percentage':
      return <>{rowModel.ad_discount_percentage?.split('.')[0]}</>;
    case 'ad_age_from':
      return <>{rowModel.ad_age_from}</>;
    case 'ad_age_to':
      return <>{rowModel.ad_age_to}</>;
    case 'ad_claimed_codes':
      return <>{rowModel.ad_claimed_codes}</>;
    case 'ad_code_count':
      return <>{rowModel.ad_code_count}</>;
    case 'ad_discount_description':
      return <>{rowModel.ad_discount_description}</>;
    case 'ad_discount_value':
      return <>{rowModel.ad_discount_value}</>;
    case 'ad_id':
      return <>{rowModel.ad_id}</>;
    case 'ad_keywords':
      return <>{rowModel.ad_keywords}</>;
    case 'ad_max_target':
      return <>{rowModel.ad_max_target}</>;
    case 'ad_name':
      return (
        <span
          onClick={() => {
            navigate({
              newView: PageRoutes.AdDetails.id,
              queryParams: {
                id: rowModel.ad_id,
              },
            });
          }}
          className={styles.DetailsLink}
        >
          {rowModel.ad_name}
        </span>
      );
    case 'ad_original_price':
      return <>{rowModel.ad_original_price}</>;
    case 'ad_rejection_reason': {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: rowModel.ad_rejection_reason,
          }}
        />
      );
    }
    case 'ad_spender_type':
      return <>{handleSpenderType(rowModel.ad_spender_type)}</>;
    case 'ad_user_contract_type':
      return <>{rowModel.ad_user_contract_type}</>;
    case 'ad_user_locations':
      return <>{rowModel.ad_user_locations}</>;
    case 'ad_views':
      return <>{rowModel.ad_views}</>;
    case 'category_name':
      return <>{rowModel.category_name}</>;
    case 'partner_legal_name':
      return <>{rowModel.partner_legal_name}</>;
    case 'partner_name':
      return <>{rowModel.partner_name}</>;
    case 'subcategory_name':
      return <>{rowModel.subcategory_name}</>;
    case 'ad_label_text':
      return <>{rowModel.ad_label_text}</>;
    case 'creator':
      return <>{rowModel.creator}</>;
    case 'publisher':
      return <>{rowModel.publisher}</>;
    case 'partnership_expert':
      return <>{rowModel.partnership_expert}</>;
    case 'partner_admin_name':
      return <>{rowModel.partner_admin_name}</>;
    case 'partner_admin_email':
      return <>{rowModel.partner_admin_email}</>;
    case 'partner_admin_phone':
      return <>{rowModel.partner_admin_phone}</>;
    case 'ad_date_range':
      return (
        <>
          {new Date(rowModel.ad_date_range.split(',')[0]).toLocaleDateString(
            'de-DE',
            {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
            }
          ) +
            ' - ' +
            new Date(rowModel.ad_date_range.split(',')[1]).toLocaleDateString(
              'de-DE',
              {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
              }
            )}
        </>
      );
    //return <>{rowModel.ad_date_range.split(',')[1]}</>;

    default:
      return <>{rowModel[column.id]}</>;
  }
};
