import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { Tabs } from '../../../components/UI/tabs/Tabs';
import { PageRoutes, useMst } from '../../../internal';
import { BannersList } from './components/BannersList';
import { Button } from '../../../components/UI/buttons/regular/Button';
import styles from './Banners.module.scss';
import { ConfirmationModal } from '../../../components/UI/confirmation-modal/ConfirmationModal';

export const Banners = observer((): React.ReactElement => {
  const { t } = useTranslation();
  const mst = useMst();
  const {
    currentPage,
    router: { navigate },
  } = mst;
  const selectedTab = currentPage?.getTabs()?.selectedTab;

  const bannersList = currentPage.getBannersList();
  const activePaginationPage = bannersList.pagination.currentPage;
  const lastPaginationPage = bannersList.pagination.totalPages;

  const tabNoTextMap: Record<string, () => JSX.Element> = {
    banners_horizontal: () => <p>{t('banners:no_banners')}</p>,
    banners_popup: () => <p>{t('banners:no_banners')}</p>,
  };

  const openCreateBannerPage = () => {
    navigate({
      newView: PageRoutes.CreateBanner.id,
      queryParams: {
        type: selectedTab?.split('_')?.[1],
      },
      params: {
        canActivate: currentPage?.checkIfBannerCanBeActivated(),
        count: currentPage.getBannersList().elements.length || 0,
      },
    });
  };

  const openBannerDetailsPage = (id: number) => {
    navigate({
      newView: PageRoutes.BannerDetails.id,
      queryParams: { id, type: selectedTab?.split('_')?.[1] },
      params: {
        canActivate: currentPage?.checkIfBannerCanBeActivated(id),
        count: currentPage.getBannersList().elements.length || 0,
      },
    });
  };

  return (
    <div className={styles.PageContainer}>
      <div className={styles.PageTitleContainer}>
        <div className={styles.PageTitle}>{t('basic:banners')}</div>
      </div>
      <div className={styles.CenteredTabsContainer}>
        <Tabs model={currentPage?.getTabs()} />
      </div>
      <div className={styles.AdListOuterContainer}>
        <Button onClick={() => openCreateBannerPage()}>
          {t('basic:create_banner')}
        </Button>
      </div>
      <div className={styles.AdListOuterContainer}>
        <BannersList
          model={bannersList}
          openBannerDetailsPage={openBannerDetailsPage}
          noElementsComponent={tabNoTextMap?.[selectedTab]?.() ?? <></>}
          currentPage={currentPage}
          activePaginationPage={activePaginationPage}
          lastPaginationPage={lastPaginationPage}
        />
      </div>
      <ConfirmationModal model={currentPage.getWarningModal()} onlyYesButton>
        <>
          <div>
            <strong>{t('banners:cannot_activate_title')}</strong>
          </div>
          <div>
            {t('banners:cannot_activate_text')}{' '}
            {currentPage.getMaxActiveBannersCount()}
          </div>
        </>
      </ConfirmationModal>
    </div>
  );
});
